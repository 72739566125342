import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

export default function App() {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      modal
      contentStyle={{ borderRadius: "5px", width: "80%", maxWidth: "400px" }}
    >
      <div
        style={{
          padding: "0.5rem",
          textAlign: "center",
        }}
      >
        <p>{t("taxes popup")}</p>
        <button
          style={{
            width: "100%",
            backgroundColor: "#06253d",
            color: "white",
            padding: "0.3rem",
          }}
          onClick={() => setOpen(false)}
        >
          OK
        </button>
      </div>
    </Popup>
  );
}
