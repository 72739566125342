import React, { useContext, useEffect, useRef, useState } from "react";
import Carosle from "./Components/Carosle";
import MenuList from "./Components/MenuList";
import HeaderInner from "./Components/HeaderInner";
import { useNavigate, useParams } from "react-router-dom";

import CustomLoader from "./Components/Loaders/CustomLoader";
import { ShowHomeMenu } from "./API/HomeMenuAPI";
import ErrorPage from "./Components/ErrorPage";
import Footer from "./Components/Footer";
import axios from "axios";
import { ThemeContext } from "./context/ThemeContext";
import { useBusinessNameContext } from "./context/BusinessName";
import AppPopup from "./Components/AppPopup";
function HomeMenu() {
  const { businessName, setBusinessName } = useBusinessNameContext();

  const business_name = localStorage.getItem("business_name");

  const lang = localStorage.getItem("lang");
  const params = useParams();
  const { theme, setTheme } = useContext(ThemeContext);
  // const { setTheme } = useContext(ThemeContext);

  console.log("themethemethemethemethemetheme", theme);

  const colorRef = useRef(null);
  let menu;
  let bgColor;
  let color;
  let headerImages;
  let logo;
  let services;
  let languages;
  let has_extra_services;
  let show_wifi_access = false;
  let show_printing_service = false;
  let notFound = "";
  const navigate = useNavigate();
  const { data, isLoading, error } = ShowHomeMenu(params.room_uuid, lang);
  // console.log("data", data.data);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${params.room_uuid}/qr-scan`);
      // notFound = "Room Not Found";
    } else {
      menu = data.data.data;
      languages = menu.Languages;
      setTheme(menu.use_new_design);
      // const images = menu?.header_images.map((image) => {
      //   return image.image;
      // });
      headerImages = menu.realvideo
        ? [{ image: menu.realvideo }, ...menu?.header_images]
        : menu?.header_images;
      logo = menu?.logo;
      show_wifi_access = menu?.show_wifi_access;
      // show printing service
      show_printing_service = menu?.show_printing_service;
      services = menu?.activeServices;
      localStorage.setItem("has_extra_services", menu?.has_extra_services);
      localStorage.setItem(
        "is_grand_millennium_barsha_hotel",
        menu?.is_grand_millennium_barsha_hotel
      );
      has_extra_services = menu?.has_extra_services;
      bgColor = menu?.header_color;
      color = menu?.color;
      // console.log("menumenu", menu);
      localStorage.setItem("logo", logo);
      localStorage.setItem("countryCode", menu?.country_code || "ae");

      // colorRef.current.style.setProperty('--color', menu?.color);
      document.documentElement.style.setProperty("--color", menu?.color);
      document.documentElement.style.setProperty(
        "--color_t",
        `${menu?.color}b3`
      );
      document.documentElement.style.setProperty(
        "--color_car",
        `${menu?.color}69`
      );
    }
    // localStorage.setItem("color", color)
    // localStorage.setItem("bgcolor", bgColor)
  }
  // useEffect(()=>{

  // },[isLoading])
  // useEffect(() => {
  //   const getData = async () => {
  //     // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
  //     setLoading(true);

  //     axios.get(`/${params.room_uuid}`).then((res) => {
  //       const data = res?.data.data;
  //       if (res.data.status == false) {
  //         setError(res.data.message)
  //       }
  //       // console.log("res", res);
  //       setHeaderImages(data?.header_images);
  //       setLogo(data?.logo);
  //       setServices(data?.activeServices);
  //       setLoading(false);
  //       // console.log("d", data);
  //     }).catch((err) => {
  //       setLoading(false);
  //       setError(err?.message)
  //       // console.log("err", err);
  //     })
  //   };
  //   getData();
  // }, []);
  const showData = () => {
    if (isLoading) {
      return <CustomLoader businessName={businessName} />;
    }
    if (error) {
      return <ErrorPage des={error} />;
    }
    // if (notFound) {
    //   return <ErrorPage des={notFound} />;
    // }
    return (
      <>
        <HeaderInner
          logo={logo}
          bgColor={bgColor}
          color={color}
          languages={languages}
        />
        <main className="home-main pad-top-0">
          {businessName &&
            businessName == "Royal M Hotel & Resort Abu Dhabi by Gewan" && (
              <AppPopup />
            )}
          <Carosle
            className={"dot-bar less-margin small-dots"}
            headerImages={headerImages}
            isFirst={true}
          />
          <div className="spacer-10 full-div"></div>
          <MenuList
            services={services}
            loading={isLoading}
            has_extra_services={has_extra_services}
            show_wifi_access={show_wifi_access}
            show_printing_service={show_printing_service}
          />{" "}
          {/* <pre>{JSON.stringify(services, null, 2)}</pre> */}
        </main>
        {/* <Footer /> */}
      </>
    );
  };

  return (
    <>
      {/* <HeaderInner logo={logo} bgColor={bgColor}
        color={color} /> */}
      {/* <main className="home-main pad-top-0"> */}
      {showData()}

      {/* </main> */}
    </>
  );
}
export default HomeMenu;
