import React, { useEffect } from "react";
import Title from "../../new-components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "../food-and-beverages/FoodAndBeverages.scss";

const HotelServices = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const metadata = location.state?.metadata;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  console.log("metadata", metadata);

  return (
    <div className={`food ${lang === "AR" && "arabic"}`}>
      <Title title={"Hotel Services"} />

      <div className="box_food image_services">
        {metadata?.map((service) => {
          if (service.id === 18) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/salon`}>
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/salon.png"}
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }

          if (service.id === 3) {
            console.log("test", service);
            return (
              <Link
                to={`/${params.room_uuid}/hotel-services/laundry/${
                  service.link.split(`${params.room_uuid}/laundry/`)[1]
                }`}
              >
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Laundry.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 5) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/houseKeeping`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Housekeeping.png"
                  }
                  alt=""
                  className="service_img"
                />

                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 15) {
            return (
              <Link to={`/${params.room_uuid}/maintenance/companies`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Maintenance.png"
                  }
                  alt=""
                  className="service_img"
                />

                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id === 7) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/conceirge`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Conceirge.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
        })}
      </div>
      <NewFooter />
    </div>
  );
};

export default HotelServices;
