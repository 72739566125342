import React, { useEffect, useState } from "react";
import axios from "../axios/index";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FaApple } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ApplePayButton = ({
  amount,
  currencyCode,
  countryCode,
  merchantIdentifier,
  merchantName,
  roomId,
  payment_method,
  is_guest,
  umbrella_room_number,
  family_name,
  isDisabled,
  setIsDisabled,
  order,
  email,
  phone,
  username,
  checkInputs,
  checked,
}) => {
  const { t } = useTranslation();

  const [applePayAvailable, setApplePayAvailable] = useState(false);
  const newDesign = localStorage.getItem("new_design");
  console.log("is new ", newDesign);

  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useEffect(() => {
    // Safely check if Apple Pay is available without causing errors
    const hasApplePay =
      typeof window !== "undefined" && window.hasOwnProperty("ApplePaySession");

    console.log("Apple Pay Session", window.ApplePaySession, hasApplePay);

    if (hasApplePay) {
      // Only check for active cards if ApplePaySession exists

      try {
        window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier)
          .then((canMakePaymentsWithActiveCard) => {
            console.log(
              canMakePaymentsWithActiveCard,
              "in here can make payyment"
            );
            setApplePayAvailable(canMakePaymentsWithActiveCard);
          })
          .catch((error) => {
            console.error("Error checking Apple Pay capability:", error);
            setApplePayAvailable(false);
          });
      } catch (error) {
        console.error("Error checking Apple Pay:", error);
        setApplePayAvailable(false);
      }
    } else {
      console.log("Apple Pay is not available in this browser/device");
      setApplePayAvailable(false);
    }
  }, [merchantIdentifier]);

  useEffect(() => {
    checkInputs();
    console.log(email, phone, username, "phhhh");
  }, [order.email, order.phone, order.name, order.username]);

  const startApplePaySession = () => {
    console.log(checked, "checkkkkkkkkk");
    if (!checked)
      return toast.error(t("Please agree to the terms and conditions"));
    if (email || username || phone) return;
    setIsDisabled(true);
    // Safety check again before starting session
    if (!applePayAvailable) {
      console.error("Apple Pay is not available");
      return;
    }

    try {
      const paymentRequest = {
        countryCode,
        currencyCode,
        supportedNetworks: ["visa", "masterCard"],
        merchantCapabilities: ["supports3DS"],
        total: {
          label: merchantName,
          amount: amount.toString(),
        },
      };

      const session = new window.ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = async (event) => {
        try {
          const response = await axios.post(
            `/${roomId}/orders/apple-pay-session`
          );
          const merchantSession = response.data; // Axios already parses JSON by default

          // Complete merchant validation
          setIsDisabled(false);
          session.completeMerchantValidation(merchantSession);
        } catch (error) {
          setIsDisabled(false);
          // alert(error);
          console.error("Merchant validation failed:", error);
          session.abort();
        }
      };

      session.onpaymentauthorized = async (event) => {
        try {
          setIsDisabled(true);
          console.log(event, "event authorize");
          console.log(event, "this is my apple events");
          console.log("Payment authorized:", event.payment);

          const response = await axios.post(
            `/${roomId}/orders/checkout`,
            {
              payment_method,
              is_guest,
              umbrella_room_number,
              family_name,
              redirect_url: "https://notrequired.com",
              is_apple_pay: 1,
              apple_pay_token: event.payment.token,
              name: order.name,
              email: order.email,
              phone: order.phone,
            },
            {
              headers: {
                lang: lang,
              },
            }
          );

          setIsDisabled(false);

          if (response.data.status) {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            if (response?.data?.data?.payment_url) {
              // setPaymentUrl(response?.data?.data?.payment_url);
            } else {
              navigate(`/${roomId}/RestaurantOrderPlaced`, {
                state: { metadata: response.data.data },
              });
            }
          } else {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            toast.error(response.data?.message);
            queryClient.invalidateQueries(`checkout`);
          }
        } catch (err) {
          setIsDisabled(false);
          toast.error(err.message);
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          console.error("Payment processing failed:", err);
        }
      };

      session.oncancel = (event) => {
        setIsDisabled(false);
        console.log("dddd", event);
      };

      session.begin();
    } catch (error) {
      setIsDisabled(false);
      // alert(error, "err");
      console.error("Error starting Apple Pay session:", error);
    }
  };

  return (
    <div
      className="padd-div text-center "
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        className="apple-pay-button order-btn"
        style={{
          opacity: (isDisabled || email || phone || username) && "60%",
          backgroundColor: newDesign === "true" ? "#fff" : "#000",
          color: newDesign === "true" ? "#000" : "#fff",
          paddingRight: "17px",
        }}
        onClick={startApplePaySession}
        type="button"
        disabled={isDisabled || email || username || phone}
      >
        <div
          style={{
            opacity: (isDisabled || email || username || phone) && "90%",
          }}
        >
          <FaApple size={18} /> Pay
        </div>
      </button>
    </div>
  );
};

export default ApplePayButton;
